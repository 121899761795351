import React from 'react'
import styled from 'styled-components'
import Card from '../components/Card'
import CardList from '../components/CardList'

const Wrapper = styled.div`
  margin: 1.5rem auto;
  max-width: ${props => props.theme.sizes.maxWidthCentered};
`

const SectionTitle = styled.p`
  font-size: 0.9rem;
  margin-bottom: 1rem;
  font-weight: bold;
`

const RelatedPostsList = ({ posts }) => {
  return (
    <Wrapper>
      <SectionTitle>こちらの記事もおすすめです</SectionTitle>
      <CardList>
        {posts.map(post => (
          <Card key={post.id} {...post} isRelatedPostList />
        ))}
      </CardList>
    </Wrapper>
  )
}

export default RelatedPostsList
