import React from 'react'
import Img from 'gatsby-image'
import styled from 'styled-components'

const Wrapper = styled.section`
  margin: 0 auto 20px;
  max-width: ${props => props.theme.sizes.maxWidthCentered};
`
const BgImg = styled(Img)`
  width: 100%;
  height: auto;
`

const Title = styled.h1`
  color: ${props => props.theme.colors.base};
  font-size: 32px;
  font-weight: 600;
  margin-bottom: 14px;
  width: 100%;
  max-width: ${props => props.theme.sizes.maxWidthCentered};
  @media screen and (max-width: 769px) {
    font-size: 24px;
  }
`

const Hero = props => (
  <Wrapper>
    <Title>{props.title}</Title>
    <BgImg
      height={props.height}
      fluid={props.image.fluid}
      backgroundColor={'#eeeeee'}
    />
  </Wrapper>
)

export default Hero
