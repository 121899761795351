import React from 'react'
import styled from 'styled-components'
import {
  FacebookShareButton,
  TwitterShareButton,
  LineShareButton,
  PocketShareButton,
  FacebookIcon,
  TwitterIcon,
  LineIcon,
  PocketIcon,
} from 'react-share'

const Wrapper = styled.div`
  margin: 0 auto 2rem auto;
  max-width: ${props => props.theme.sizes.maxWidthCentered};
  display: flex;
  .SocialMediaShareButton {
    margin-right: 0.5rem;
  }
`

const ShareButtons = props => {
  return (
    <Wrapper>
      <FacebookShareButton url={props.url} quote={props.title}>
        <FacebookIcon size={32} round />
      </FacebookShareButton>
      <TwitterShareButton url={props.url} title={props.title}>
        <TwitterIcon size={32} round />
      </TwitterShareButton>
      <LineShareButton url={props.url} title={props.title}>
        <LineIcon size={32} round />
      </LineShareButton>
      <PocketShareButton url={props.url} title={props.title}>
        <PocketIcon size={32} round />
      </PocketShareButton>
      <div className="SocialMediaShareButton">
        <a
          href="https://b.hatena.ne.jp/entry/"
          className="hatena-bookmark-button"
          data-hatena-bookmark-layout="touch-counter"
          data-hatena-bookmark-width="32"
          data-hatena-bookmark-height="32"
          title="このエントリーをはてなブックマークに追加"
        >
          <img
            src="https://b.st-hatena.com/images/v4/public/entry-button/button-only@2x.png"
            alt="このエントリーをはてなブックマークに追加"
            width="20"
            height="20"
            style={{ border: 'none' }}
          />
        </a>
      </div>
    </Wrapper>
  )
}

export default ShareButtons
