import React from 'react'
import { graphql, Link } from 'gatsby'
import Helmet from 'react-helmet'
import config from '../utils/siteConfig'
import Layout from '../components/Layout'
import Hero from '../components/Hero'
import Container from '../components/Container'
import PageBody from '../components/PageBody'
import TagList from '../components/TagList'
import PostLinks from '../components/PostLinks'
import PostDetails from '../components/PostDetails'
import SEO from '../components/SEO'
import ShareButtons from '../components/ShareButtons'
import CardAuthor from '../components/CardAuthor'
import RelatedPostsList from '../components/RelatedPostsList'
import RelatedPostsContainer from '../components/RelatedPostsContainer'

const PostTemplate = ({ data, pageContext }) => {
  const {
    title,
    slug,
    author,
    heroImage,
    body,
    publishDate,
    updateDate,
    tags,
    relatedPosts,
  } = data.contentfulPost
  const postNode = data.contentfulPost

  const previous = pageContext.prev
  const next = pageContext.next

  return (
    <Layout>
      <Helmet>
        <title>{`${title} | ${config.siteTitle}`}</title>
      </Helmet>
      <SEO pagePath={slug} postNode={postNode} postSEO />
      <Container>
        <Hero title={title} image={heroImage} height={'100%'} />
        {tags && <TagList tags={tags} />}
        <PostDetails
          date={publishDate}
          updatedOn={updateDate}
          timeToRead={body.childMarkdownRemark.timeToRead}
        />
        <ShareButtons title={title} url={`${config.siteUrl}/${slug}`} />
        {author && <CardAuthor {...author} />}
        <PageBody body={body} />
        <ShareButtons title={title} url={`${config.siteUrl}/${slug}`} />
        <div style={{ textAlign: 'center' }}>
          <Link to={`/`}>トップページへ</Link>
        </div>
      </Container>

      <PostLinks previous={previous} next={next} />

      {relatedPosts && relatedPosts.length && (
        <RelatedPostsContainer>
          <RelatedPostsList posts={relatedPosts} />
        </RelatedPostsContainer>
      )}
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    contentfulPost(slug: { eq: $slug }) {
      title
      slug
      author {
        name
        description {
          childMarkdownRemark {
            timeToRead
            html
            excerpt(pruneLength: 320)
          }
        }
        twitterAccount
        profileImage {
          title
          fluid(maxWidth: 1800) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
          ogimg: resize(width: 1800) {
            src
            width
            height
          }
        }
      }
      metaDescription {
        internal {
          content
        }
      }
      publishDate(formatString: "MMMM DD, YYYY")
      publishDateISO: publishDate(formatString: "YYYY-MM-DD")
      tags {
        title
        id
        slug
      }
      updateDate(formatString: "MMMM DD, YYYY")
      updateDateISO: updateDate(formatString: "YYYY-MM-DD")
      tags {
        title
        id
        slug
      }
      heroImage {
        title
        fluid(maxWidth: 1800) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
        ogimg: resize(width: 1800) {
          src
          width
          height
        }
      }
      body {
        childMarkdownRemark {
          timeToRead
          html
          excerpt(pruneLength: 320)
        }
      }
      relatedPosts {
        title
        id
        slug
        publishDate(formatString: "MMMM DD, YYYY")
        heroImage {
          title
          fluid(maxWidth: 1800) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
        }
        body {
          childMarkdownRemark {
            timeToRead
            html
            excerpt(pruneLength: 80)
          }
        }
      }
    }
  }
`

export default PostTemplate
