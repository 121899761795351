import React from 'react'
import styled from 'styled-components'
import Img from 'gatsby-image'

const Wrapper = styled.div`
  margin: 0 auto 1rem;
  padding: 1rem;
  max-width: ${props => props.theme.sizes.maxWidthCentered};
  background: white;
  overflow: hidden;
  box-shadow: 6px 6px 17px 0 rgba(82, 76, 76, 0.06);
`

const DesktopWrapper = styled.div`
  display: flex;
  @media screen and (max-width: ${props => props.theme.responsive.small}) {
    display: none;
  }

  div.img-wrapper {
    flex-basis: 20%;
    padding: 0 0.5rem;
  }

  div.content-wrapper {
    flex-basis: 80%;
    padding: 0 0.5rem;
  }

  div.name-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    a {
      margin-left: 4px;
    }
  }

  span.name {
    font-size: 20px;
    font-weight: bold;
  }

  p.description {
    font-size: 14px;
    line-height: 1.5em;
    margin-bottom: 4px;
  }
`

const MobileWrapper = styled.div`
  @media screen and (min-width: ${props => props.theme.responsive.small}) {
    display: none;
  }

  div.img-name-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
  }

  div.img-wrapper {
    flex-basis: 30%;
  }
  div.name-wrapper {
    flex-basis: 70%;
    padding: 0 0.5rem;
    display: flex;
    align-items: center;
    span {
      font-size: 20px;
      font-weight: bold;
    }
    a {
      margin-left: 4px;
    }
  }

  div.content-wrapper {
    font-size: 14px;
    line-height: 1.5em;
  }
`

const TwitterLogo = styled.img`
  width: 32px;
  display: inline;
`

const CardAuthor = ({ name, description, profileImage, twitterAccount }) => {
  return (
    <Wrapper>
      {/* Desktop */}
      <DesktopWrapper>
        <div className="img-wrapper">
          <Img
            fluid={profileImage.fluid}
            imgStyle={{ borderRadius: '10rem' }}
          />
        </div>
        <div className="content-wrapper">
          <div className="name-wrapper">
            <span className="name">{`${name}`}</span>
            {TwitterLogo && (
              <a
                href={`https://twitter.com/${twitterAccount}`}
                rel="noopener noreferrer"
                target="_blank"
              >
                <TwitterLogo src="/logos/twiiter_48.png" />
              </a>
            )}
          </div>
          <p className="description">{`${description.childMarkdownRemark.excerpt}`}</p>
        </div>
      </DesktopWrapper>

      {/* Mobile */}
      <MobileWrapper>
        <div className="img-name-wrapper">
          <div className="img-wrapper">
            <Img
              fluid={profileImage.fluid}
              imgStyle={{ borderRadius: '10rem' }}
            />
          </div>
          <div className="name-wrapper">
            <span>{`${name}`}</span>
            {TwitterLogo && (
              <a
                href={`https://twitter.com/${twitterAccount}`}
                rel="noopener noreferrer"
                target="_blank"
              >
                <TwitterLogo src="/logos/twiiter_48.png" />
              </a>
            )}
          </div>
        </div>
        <div className="content-wrapper">
          <p className="description">{`${description.childMarkdownRemark.excerpt}`}</p>
        </div>
      </MobileWrapper>
    </Wrapper>
  )
}

export default CardAuthor
